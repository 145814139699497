
<script>
import CompHeader from '@/components/CompHeader.vue'
import CompLearnMore from '@/components/CompLearnMore.vue'
import CompEmail from '@/components/CompEmail.vue'
import CompEmailSent from '@/components/CompEmailSent.vue'


import {_requestEmail}  from '@/js/functions.js'

export default {
    data() {
        return {

            showEmailRequestModal: false,
            emailSentShow: false,
            emailSentSpin: false,
             width:1440,
        };
    },
     mounted() {
         window.addEventListener('resize', this.onResize);
     },
     computed: {
         scaleStyle() {
             let scale = window.innerWidth / this.width;
             scale = scale > 1 ? 1 : scale;
             if (scale < 1)
             {
                 return {
                     transform: `scale(${scale})`,
                     transformOrigin: 'top left',
                     width: `${this.width}px`
                 };
             }
             else{
                 let slide = (window.innerWidth - this.width)/2;
                 return {

                     transform: `translate(${slide}px, 0)`,
                     transformOrigin: 'top left',
                     width: `${this.width}px`,
                 };
             }
         }
     },
    components: {
        CompHeader,
        CompLearnMore,
        CompEmail,
        CompEmailSent
    },
    methods: {
         onResize() {
             this.$forceUpdate(); // Force Vue to re-render on window resize
         },
        requestEmail(emailContents) {
            _requestEmail(this, emailContents);
            this.showEmailRequestModal = false;
            this.emailSentSpin = true;
            this.emailSentShow = true;

            setTimeout(() => {
                this.emailSentSpin = false;
            }, Math.floor(Math.random() * 1000) + 700);
        }
    },
}

</script>

<template>


<CompEmail @requestEmail="requestEmail" :showModal="showEmailRequestModal" @close="showEmailRequestModal = false" v-show="showEmailRequestModal" class="fixed inset-0 flex items-center justiwfy-center z-50 "/>
<CompEmailSent @buttonClicked="emailSentShow = false" :emailSentShow="emailSentShow" :spin="emailSentSpin" />

<div id="app" class="app-container">
    <div :style="scaleStyle" class="scale-container">
        <div class="w-full h-[5027px] pb-[74px]  flex-col justify-start items-center inline-flex">

            <div class="self-stretch flex-col justify-start items-center gap-0.5 inline-flex">
                <CompHeader @openEmailRequestModal="showEmailRequestModal=true"/>
                <div class=" top-[1691px] absolute flex-col justify-start items-center gap-12 inline-flex">
                    <div class="w-[953px] text-center text-black text-5xl font-bold   leading-[57.60px]">We’re on a mission to transform medical-education for the next generation</div>
                    <div class="justify-start items-start gap-12 inline-flex">
                        <div class="self-stretch px-[42px] py-8 bg-indigo-50 rounded flex-col justify-start items-center gap-8 inline-flex">
                            <div class="text-center text-black text-2xl font-bold font-['SF Pro Text'] leading-loose">Hello healthcare<br/>institutions</div>
                            <div class="w-[243px] text-center text-black text-xl font-normal font-['SF Pro Text'] leading-normal">We make invisible aspects of students learning - visible to you. Ask us how.</div>
                        </div>
                        <div class="self-stretch px-[59px] py-8 bg-indigo-50 rounded flex-col justify-start items-center gap-8 inline-flex">
                            <div class="text-center text-black text-2xl font-bold font-['SF Pro Text'] leading-loose">Hi Faculty</div>
                            <div class="w-[206px] text-center text-black text-xl font-normal font-['SF Pro Text'] leading-normal">We help you teach better using less time. Ask us how.</div>
                        </div>
                        <div class="self-stretch px-[34px] py-8 bg-indigo-50 rounded flex-col justify-start items-center gap-8 inline-flex">
                            <div class="text-center text-black text-2xl font-bold font-['SF Pro Text'] leading-loose">Hey Students</div>
                            <div class="w-64 text-center text-black text-xl font-normal font-['SF Pro Text'] leading-normal">Internalise triage and patient examination skills essential to your professional success. </div>
                        </div>
                    </div>
                </div>
                <div class="w-[613px]  top-[158px] absolute text-black text-[32px] font-bold   leading-[38.40px]">We’ve been there where you are</div>
                <div class="w-[405px]  top-[433px] absolute text-black text-[32px] font-bold   leading-[38.40px]">We had a deep dive</div>
                <div class="w-[680px]  top-[229px] absolute text-indigo-950 text-base font-normal   leading-[18.80px]">We’ve been medical students, and now working as clinical educators in healthcare institutions. We train young doctors who will eventually take our place in the future.<br/><br/>With over 30 years of combined medical and teaching experience, we understand the challenges that students face in internalising and developing essential diagnostic and assessment skills in an ever-changing environment. <br/><br/>Furthermore, we recognise that students often struggle to apply their knowledge and essential practices and procedures under the stress of a clinical setting.</div>
                <div class="w-[680px]  top-[504px] absolute text-indigo-950 text-base font-normal   leading-[18.80px]">In 2020, we embarked on a journey to uncover the layers of problems in healthcare education and examine their impact on teachers, students, and healthcare education administrators. We recognized that, despite their best efforts, students were receiving inconsistent amounts of time and attention from their teachers, resulting in varying levels of hands-on training. This discrepancy arises from the distinct attributes of each campus, the availability of time from mentors, and the level of participation and interest exhibited by individual students.<br/><br/>However, what remains consistent is the Teaching institution's desire to contribute and help everyone, standardize, supervise, and provide the necessary training for every learner to become a dependable healthcare professional.<br/><br/>After spending over 10,000 hours critically exploring, investigating and testing, we have developed key insights.</div>
                <div class="w-[680px]  top-[855px] absolute text-indigo-950 text-base font-normal   leading-[18.80px]">Having been students ourselves and now being teachers, we have a unique understanding of both perspectives. Through this experience, we have identified key areas of interest for hospital administration: providing assistance and support when learning is not progressing as desired, and having the ability to track progress across different campuses and semesters.<br/><br/>We are aware of the strong desire teachers have to help their students, but due to time constraints and demanding medical requirements, they often have limited visibility and insight into the development of young doctors' practices.<br/><br/>Additionally, we recognize that students are doing their best, but they lack ongoing supervision and guidance in critical areas such as critical thinking, evaluation, and creating accurate medical records.</div>
                <div class="w-[680px]  top-[1187px] absolute text-indigo-950 text-base font-normal   leading-[18.80px]">We fully understand the non-negotiable aspects and the challenges that clinical education administration faces when adopting new systems. We are also aware that despite the desire to improve, there may be barriers that prevent the adoption of these systems.<br/><br/>That is why we have developed MEANI. <br/><br/>MEANI serves as a training assistant for young doctors, a co-pilot for teachers, and a dashboard for supervisors in clinical learning environments.<br/><br/>Using MEANI, now you can see what was previously invisible, measure what was previously unmeasurable, and provide administration with the crucial insights to enhance the comparability of student clinical learning opportunities. We have created the system that we wished we had when we were students ourselves.</div>
                <div class="w-[680px]  top-[1538px] absolute text-indigo-950 text-base font-normal   leading-[18.80px]">We’re excited to share this system and its benefits with everyone. If you are a healthcare providers where students learn how to be a better doctor, we have solution that will ensure they become one.</div>
                <div class="w-[400px]  top-[784px] absolute text-black text-[32px] font-bold   leading-[38.40px]">We know what matters</div>
                <div class="w-[452px]  top-[1116px] absolute text-black text-[32px] font-bold   leading-[38.40px]">That’s why we built MEANI</div>
                <div class="w-[446px]  top-[1467px] absolute text-black text-[32px] font-bold   leading-[38.40px]">And we’re happy to share it</div>
                <div class="left-[142px] top-[3620px] absolute justify-start items-start gap-4 inline-flex">
                    <div class="w-10 h-10 relative"></div>
                    <div class="w-10 h-10 relative"></div>
                    <div class="w-10 h-10 relative"></div>
                </div>
                <div class="top-[3221px] absolute text-black text-5xl font-bold   leading-[57.60px]">We love questions</div>
                <div class="w-[680px]  top-[3310px] absolute"><span style="text-indigo-950 text-base font-normal   leading-[18.80px]">We know you're creative and would have many of those, but please contact us if you have any of these queries as well.<br/><br/></span><span style="text-indigo-950 text-base font-normal   leading-[18.80px]">How to book a demo for your institution?<br/>How it works with preclinical and clinical clerkships?<br/>And how much can in it help in research and validation?<br/>Why do we love Texas?</span></div>
                <div class="w-[207px]  top-[3544px] absolute"><span style="text-indigo-950 text-base font-normal   leading-[18.80px]">3911 4th Street, Lubbock, Texas 79415, United States<br/></span><span style="text-indigo-950 text-base font-normal   underline leading-[18.80px]">info@buclid.com</span></div>
                <div class=" top-[3501px] absolute text-black text-2xl font-semibold font-['SF Pro Text'] leading-loose">And we do love Texas</div>
                <div class="w-[1440px] h-[323px] top-[3740px] absolute bg-slate-200">
                    <div class="w-[232px] h-[57px] left-[408px] top-[199px] absolute">
                        <div class="w-48 left-[40px] top-0 absolute text-indigo-950 text-base font-normal  ">3911 4th Street, Lubbock, Texas 79415, United States</div>
                        <div class="w-6 h-6 left-0 top-[10px] absolute"></div>
                    </div>
                    <div class="w-[304px] left-[701px] top-[202px] absolute">
                        <div class="w-[264px] left-[40px] top-[1px] absolute text-indigo-950 text-base font-normal  ">info@buclid.com</div>
                        <div class="w-6 h-6 left-0 top-0 absolute flex-col justify-start items-start inline-flex"></div>
                    </div>
                    <div class="left-[929px] top-[202px] absolute justify-start items-start gap-4 inline-flex">
                        <div class="w-10 h-10 relative"></div>
                        <div class="w-10 h-10 relative"></div>
                        <div class="w-10 h-10 relative"></div>
                    </div>
                    <div class="w-[645px] h-[0px] left-[422px] top-[170px] absolute border border-slate-300"></div>
                    <div class="w-[120px] left-[685px] top-[83px] absolute opacity-50 text-indigo-950 text-[33.10px] font-extrabold font-['Montserrat']">MAENI</div>
                </div>
                <CompLearnMore @requestEmail="requestEmail" class="top-[2108px]" style="margin-top: 50px;"/>
            </div>
        </div>
    </div>
</div>
</template>


<style>
.scale-container {
  transform-origin: top left;
  width: 1440px; /* use natural width */
}

@media (max-width: 1440px) {
  .scale-container {
    transform: scale(calc(100vw / 1440));
  }
}
</style>
