<script>
 export default {
   data() {
     return {
       email:'',
       name:'',
       role:'',
       message:''
     };
   },
   components: {
   },
   methods: {
     openModal() {
       this.modalOpen = true;
     },
     requestEmail() {
       this.$emit('requestEmail', {
         email:this.email,
         name: this.name,
         role: this.role,
         message: this.message
       });
     }
   },
   props: {
     showModal: {
       type: Boolean,
       required: true,
       default: false
     }
   },
 };
</script>
<template>
    <div class="modal-overlay" v-if="showModal"></div>
    <div v-if="showModal" class="modal w-[592px] h-[832px] p-8 bg-white rounded-lg shadow flex-col justify-start items-center gap-2 inline-flex">
        <div class="w-[321.76px] h-[200px] relative">
            <img class=" absolute origin-top-left" src="@/img/Group 1000004420.png" />
        </div>
        <div class="  text-indigo-950 text-xl font-semibold font-['SF Pro Text'] leading-normal">Book a demo</div>
        <div class="w-[528px] text-indigo-950 text-base font-normal font-['SF Pro Text'] leading-normal">Experience the power of Buclid firsthand. Book a demo today to see if it's the perfect fit for your needs. Let's talk and find out!</div>
        <div class="w-6 h-4 relative">
            <div class="w-6 h-4 left-0 top-0 absolute"></div>
        </div>
        <div class="h-16 rounded flex-col justify-start items-start gap-2 flex">
            <div class="  h-6 flex-col justify-start items-start gap-1 flex">
                <div class="  text-slate-600 text-sm font-medium font-['SF Pro Text'] leading-normal">Your name</div>
            </div>
            <div class="w-[396px] h-8 px-3 py-2 bg-white rounded border border-gray-300 justify-center items-center inline-flex">
                <input v-model="name" v-type="text" class="w-[372px] text-black-400  font-normal font-['SF Pro Text'] leading-none" placeholder="First and last name"/>
            </div>
        </div>
        <div class="h-16 flex-col justify-start items-start gap-2 flex">
            <div class="  h-6 flex-col justify-start items-start gap-1 flex">
                <div class="  text-slate-600 text-sm font-medium font-['SF Pro Text'] leading-normal">Your role</div>
            </div>
            <div class="w-[396px] h-8 px-3 py-2 bg-white rounded border border-gray-300 justify-center items-center inline-flex">
                <input v-type="text" v-model="role" class="w-[372px] text-black-400  font-normal font-['SF Pro Text'] leading-none" placeholder="Coordinator, Educator, Administrator, etc.."/>
            </div>
        </div>
        <!-- <div class="h-16 rounded flex-col justify-start items-start gap-2 flex">
             <div class="  h-6 flex-col justify-start items-start gap-1 flex">
             <div class="  text-slate-600 text-sm font-medium font-['SF Pro Text'] leading-normal">Phone number</div>
             </div>
             <div class="w-[396px] h-8 px-3 py-2 bg-white rounded border border-gray-300 justify-center items-center inline-flex">
             <input v-type="text" class="w-[372px] text-black-400  font-normal font-['SF Pro Text'] leading-none" placeholder=""/>
             </div>
             </div> -->
        <div class="h-16 rounded flex-col justify-start items-start gap-2 flex">
            <div class="  h-6 flex-col justify-start items-start gap-1 flex">
                <div class="  text-slate-600 text-sm font-medium font-['SF Pro Text'] leading-normal">Email</div>
            </div>
            <div class="w-[396px] h-8 px-3 py-2 bg-white rounded border border-gray-300 justify-center items-center inline-flex">
                <input v-type="text" v-model="email" class="w-[372px] text-black-400  font-normal font-['SF Pro Text'] leading-none" placeholder="Your Email"/>
            </div>
        </div>
        <div class="h-24 rounded flex-col justify-start items-start gap-2 flex">
            <div class="  h-6 flex-col justify-start items-start gap-1 flex">
                <div class="  text-slate-600 text-sm font-medium font-['SF Pro Text'] leading-normal">Give us some context</div>
            </div>
            <textarea class="w-[396px] relative bg-white rounded border border-gray-300" v-model="message" placeholder="Which institution do you represent? What are you keen to learn?"></textarea>
        </div>
        <div class="w-6 h-4 relative">
            <div class="w-6 h-4 left-0 top-0 absolute"></div>
        </div>
        <div class="  justify-end items-center gap-2 inline-flex">
            <button @click="requestEmail" class="px-4 py-2 bg-blue-600 rounded justify-start items-center gap-2 flex">
                <div class="text-center text-white text-xs font-semibold font-['SF Pro Text'] leading-none">Submit</div>
            </button>
            <button @click="$emit('close')" class="px-4 py-2 bg-white rounded border border-gray-300 justify-start items-center gap-2 flex">
                <div class="text-center text-slate-500 text-xs font-medium font-['SF Pro Text'] leading-none">Cancel</div>
            </button>
        </div>
    </div>
</template>

<style>
.modal-overlay {
  content: '';
  position: absolute;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  background: #2c3e50;
  opacity: 0.6;
  cursor: pointer;
}

.modal {
  position: absolute;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
    margin-top: 25%;
    margin-left: 25%;
    /* margin: auto; */
  text-align: center;
  width: fit-content;
  height: fit-content;
  max-width: 592px;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  background: #FFF;
  z-index: 999;
  transform: none!;
}
@media (max-width: 1440px) {
  .scale-container {
    transform: none!;
  }
}
</style>
