<script>
 import CompHeader from '@/components/CompHeader.vue'
 import CompLearnMore from '@/components/CompLearnMore.vue'
 import CompEmail from '@/components/CompEmail.vue'
 import CompEmailSent from '@/components/CompEmailSent.vue'

 import {_requestEmail}  from '@/js/functions.js'

 export default {
     data() {
         return {
             showEmailRequestModal: false,
             emailSentShow: false,
             emailSentSpin: false,
             width:1440,
         };
     },
     mounted() {
         window.addEventListener('resize', this.onResize);
     },
     components: {
         CompHeader,
         CompLearnMore,
         CompEmail,
         CompEmailSent
     },
     computed: {
         scaleStyle() {
             let scale = window.innerWidth / this.width;
             scale = scale > 1 ? 1 : scale;
             if (scale < 1)
             {
                 return {
                     transform: `scale(${scale})`,
                     transformOrigin: 'top left',
                     width: `${this.width}px`
                 };
             }
             else{
                 let slide = (window.innerWidth - this.width)/2;
                 return {

                     transform: `translate(${slide}px, 0)`,
                     transformOrigin: 'top left',
                     width: `${this.width}px`,
                 };
             }
         }
     },
     methods: {
         onResize() {
             this.$forceUpdate(); // Force Vue to re-render on window resize
         },
         incrCounter() {
             if (this.pinCounter >= 0 ) {
                 this.pinCounter += 1;
                 this.pinnedVisible = true;
             }
             else {
                 this.pinCounter += 1;
                 this.pinnedVisible = false;
             }
         },
         requestEmail(emailContents) {
             _requestEmail(this, emailContents);
             this.showEmailRequestModal = false;
             this.emailSentSpin = true;
             this.emailSentShow = true;

             setTimeout(() => {
                 this.emailSentSpin = false;
             }, Math.floor(Math.random() * 1000) + 700);
         }
     },
 }

</script>
<template>


 <CompEmailSent @buttonClicked="emailSentShow = false" :emailSentShow="emailSentShow" :spin="emailSentSpin" />
  <div id="app" class="app-container" style="background: #C6CED6">

    <div :style="scaleStyle" class="scale-container">
        <div class="w-full h-[5027px] pb-[74px] flex-col justify-start items-center inline-flex">
            <div class="self-stretch flex-col justify-start items-center gap-0.5 inline-flex">
                <CompHeader @openEmailRequestModal="showEmailRequestModal=true"/>
                <div class="w-[1440px] h-[794px] relative">
                    <div class="w-[1440px] h-[794px] left-0 top-0 absolute bg-slate-100"></div>
                    <div class="w-[497px] left-[116px] top-[363px] absolute text-indigo-950 text-[32px] font-normal  leading-[37.61px]">Evaluate, capture, and advance educational equity in clinical settings, standarizing learning across campuses.</div>
                    <div class="w-[478px] left-[114px] top-[151px] absolute text-black text-5xl font-bold leading-[57.60px]">We empower you to see what’s crucial yet invisible</div>
                    <div class="w-[568px] h-[590px] left-[792px] top-[137px] absolute bg-green-100 rounded-[5px]"></div>
                    <div class="w-[690.11px] h-[664px] left-[712px] top-[63px] absolute">
                        <div class="w-[136.11px] h-[131.83px] left-[554px] top-[106px] absolute">
                            <div class="w-[119.81px] h-[131.83px] left-0 top-0 absolute">
                                <div class="w-[92.37px] h-[125.99px] left-[13.72px] top-[24.13px] absolute">
                                    <div class="w-[92.37px] h-[125.98px] left-0 top-[0.01px] absolute">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-[644px] h-[664px] left-0 top-0 absolute">
                            <img class="w-[519px] h-[664px] left-[100px] top-0 absolute origin-top-left" src="@/img/Purchased-AdobeStock_320523164-PhotoRoom_man1.png" />
                            <div class="w-[193px] h-[166.38px] left-0 top-[143px] absolute">
                                <div class="w-[193px] h-[166.38px] left-0 top-0 absolute bg-white rounded shadow"></div>
                                <div class="left-[19.01px] top-[25px] absolute text-gray-900 text-sm font-bold font-['Plus Jakarta Sans']">Medical education</div>
                                <div class="left-[19px] top-[44.94px] absolute text-gray-900 text-[8.56px] font-semibold font-['Plus Jakarta Sans']">Better across the board</div>
                                <div class="w-[146.18px] h-[53.44px] left-[22.82px] top-[85px] absolute">
                                    <img class="w-[519px] h-[664px] left-[500px] top-[-400px] absolute origin-top-left" src="@/img/spring1.svg" />
                                    <div class="w-[15.21px] h-[19.01px] left-0 top-[34.42px] absolute bg-stone-300 rounded-sm"></div>
                                    <div class="w-[15.21px] h-[13.31px] left-[21.87px] top-[40.13px] absolute bg-stone-300 rounded-sm"></div>
                                    <div class="w-[15.21px] h-[32.33px] left-[43.73px] top-[21.11px] absolute bg-stone-300 rounded-sm"></div>
                                    <div class="w-[15.21px] h-[22.82px] left-[65.60px] top-[30.62px] absolute bg-stone-300 rounded-sm"></div>
                                    <div class="w-[15.21px] h-[49.44px] left-[87.47px] top-[4px] absolute bg-blue-600 rounded-sm"></div>
                                    <div class="w-[15px] h-[51px] left-[109.18px] top-[2px] absolute bg-blue-600 rounded-sm"></div>
                                    <div class="w-[15px] h-[53px] left-[131.18px] top-0 absolute bg-blue-600 rounded-sm"></div>
                                </div>
                            </div>
                        </div>
                    </div>









                    <button @click="showEmailRequestModal = true" class="w-[360px] h-[70px] px-[60px] py-[15px] left-[140px] top-[555px] absolute bg-blue-600 rounded-lg justify-center items-center gap-[15px] inline-flex" style="padding-top:5px; padding-bottom:5px;">
                        <div class="text-center text-white text-[22.50px] font-semibold font-['SF Pro Text']" style="font-size: 2rem;">Inquire now</div>
                    </button>
                    <CompEmail @requestEmail="requestEmail" :showModal="showEmailRequestModal" @close="showEmailRequestModal = false" v-show="showEmailRequestModal" class="fixed inset-0 flex items-center justiwfy-center z-50 ">
                    </CompEmail>
                    <div class="left-[116px] top-[663px] absolute text-center text-indigo-950 text-2xl font-normal   leading-7">Designed by medical professionals who <br>understand the nuances behind <br>academic excellence</div>
                </div>
                <div class="w-[1440px] h-[783px] relative">
                    <div class="w-[1440px] h-[783px] left-0 top-0 absolute bg-white"></div>
                    <div class="w-[559.83px] h-[599px] left-[127px] top-[103px] absolute">
                        <div class="w-[488px] h-[599px] left-0 top-0 absolute">
                            <div class="w-[475px] h-[528px] left-0 top-[63px] absolute bg-blue-100 rounded-[5px]"></div>
                            <img class="w-[488px] h-[599px]  top-0 absolute origin-top-left" src="@/img/girl3f.png" />
                            <img class="w-[158px] h-[97] left-[400px] top-[100px] absolute origin-top-left" src="@/img/Group.svg" />
                        </div>
                    </div>
                    <div class="w-[593px] h-[479px] left-[792px] top-[154px] absolute">
                        <div class="w-[593px] left-0 top-[213px] absolute text-black text-[32px] font-normal   leading-[37.61px]">Seamlessly keep track of students' learning progress in clinical clerkships across hospital wards, operating rooms, and clinic exam rooms. <br> <br>Measure your faculty's efficiency in delivering student feedback.</div>
                        <div class="w-[555px] left-0 top-[49px] absolute text-black text-5xl font-bold   leading-[57.60px]">Measure critical success indicators</div>
                        <div class="left-0 top-0 absolute text-blue-600 text-2xl font-semibold font-['SF Pro Text'] leading-loose">As the Clerkship Coordinator</div>
                    </div>
                </div>
                <div class="w-[1440px] h-[783px] relative">
                    <div class="w-[1440px] h-[783px] left-0 top-0 absolute bg-slate-100"></div>
                    <div class="w-[620.12px] h-[555px] left-[709px] top-[106px] absolute">
                        <div class="w-[558px] h-[555px] left-0 top-0 absolute">
                            <div class="w-[475px] h-[528px] left-[83px] top-[27px] absolute bg-blue-100 rounded-[5px]"></div>
                            <img class="w-[549px] h-[554px] left-0 top-0 absolute" src="@/img/Purchased-AdobeStock_320523164-PhotoRoom_group.png" />
                            <img class="w-[80]  left-[500px] top-[20px] absolute" src="@/img/Group(1).svg" />
                        </div>
                        <div class="w-[240.12px] h-[207px] left-[380px] top-[327px] absolute">
                            <div class="w-[240.12px] h-[207px] left-0 top-0 absolute bg-white rounded-md shadow"></div>
                            <div class="left-[23.66px] top-[22.47px] absolute text-gray-900 text-base font-bold font-['Plus Jakarta Sans']">Teach what matters</div>
                            <div class="left-[23.66px] top-[59.14px] absolute text-gray-900 text-2xl font-bold font-['Plus Jakarta Sans']">84%</div>
                            <div class="left-[86.35px] top-[73.34px] absolute text-gray-900 text-[10.65px] font-semibold font-['Plus Jakarta Sans']">+0.4%</div>
                            <div class="w-[183px] h-[87.97px] left-[28px] top-[96px] absolute">
                                <div class="w-[19px] h-[61px] left-0 top-[7px] absolute bg-indigo-300 rounded-sm"></div>
                                <div class="w-[19px] h-[61px] left-[28px] top-[7px] absolute bg-blue-100 rounded-sm"></div>
                                <div class="w-[19px] h-[57px] left-[55px] top-[11px] absolute bg-blue-600 rounded-sm"></div>
                                <div class="w-[19px] h-[65px] left-[82px] top-[3px] absolute bg-indigo-300 rounded-sm"></div>
                                <div class="w-[18.93px] h-[61.51px] left-[109.21px] top-[6.91px] absolute bg-blue-600 rounded-sm"></div>
                                <div class="w-[19px] h-[65px] left-[136px] top-[3px] absolute bg-indigo-300 rounded-sm"></div>
                                <div class="w-[19px] h-[68px] left-[164px] top-0 absolute bg-indigo-300 rounded-sm"></div>
                                <div class="left-[5.12px] top-[71.97px] absolute text-indigo-950 text-xs font-medium font-['Manrope']">M</div>
                                <div class="left-[32.32px] top-[71.97px] absolute text-indigo-950 text-xs font-medium font-['Manrope']">T</div>
                                <div class="left-[58.35px] top-[71.97px] absolute text-indigo-950 text-xs font-medium font-['Manrope']">W</div>
                                <div class="left-[86.74px] top-[71.97px] absolute text-indigo-950 text-xs font-medium font-['Manrope']">T</div>
                                <div class="left-[113.94px] top-[71.97px] absolute text-indigo-950 text-xs font-medium font-['Manrope']">F</div>
                                <div class="left-[141.15px] top-[71.97px] absolute text-indigo-950 text-xs font-medium font-['Manrope']">S</div>
                                <div class="left-[168.36px] top-[71.97px] absolute text-indigo-950 text-xs font-medium font-['Manrope']">S</div>
                            </div>
                        </div>
                    </div>
                    <div class="w-[593px] h-[494px] left-[79px] top-[145px] absolute">
                        <!-- <div class="w-[593px] left-0 top-[219px] absolute text-right text-black text-[32px] font-normal   leading-[37.61px]">Take students beyond knowing, enable them understand, adopt and develop core clinical competencies.</div> -->
                        <div class="w-[593px] left-0 top-[150px] absolute text-right text-black text-[32px] font-normal   leading-[37.61px]">Give instant feedback without the clutter of unnecessary emails. Our system respects your time, offering efficient ways to provide valuable insights to your students.
</div>
                        <div class=" left-[59px] top-[20px] absolute text-right text-black text-5xl font-bold   leading-[57.60px]">Accelerate student success</div>
                        <div class=" left-[100px] top-[380px] absolute text-right text-black text-[32px] font-normal   leading-[37.61px]">Focus more on your clinical duties while demonstrating your commitment to students' professional growth, supporting your path to tenure and promotion.<br/></div>
                        <div class="left-[334px] top-0 absolute text-right text-blue-600 text-2xl font-semibold ">As the Faculty Educator</div>
                    </div>
                </div>
                <div class="w-[1440px] h-[783px] relative">
                    <div class="w-[1440px] h-[783px] left-0 top-0 absolute bg-white"></div>
                    <div class="w-[514px] left-[812px] top-[272px] absolute text-right text-black text-[32px] font-normal   leading-[37.61px]">Benefit from individualized support from dedicated faculty members.<br> <br>Ensure timely and accountable feedback from your educators. <br> <br>Develop your medical judgment to achieve superior clinical outcomes.                        <br/></div>
                    <div class="w-[951px] left-[385px] top-[172px] absolute text-right text-black text-5xl font-bold   leading-[57.60px]">Enhance your clinical gestalt</div>
                    <div class="left-[1159px] top-[127px] absolute text-right text-blue-600 text-2xl font-semibold font-['SF Pro Text'] leading-loose">As the Student</div>
                    <div class="w-[618px] h-[643px] left-[91px] top-[26px] absolute">
                        <div class="w-[513px] h-[643px] left-0 top-0 absolute">
                            <div class="w-[475px] h-[528px] left-[19px] top-[101px] absolute bg-indigo-50 rounded-[5px]"></div>
                            <img class="w-[513px] h-[643px]  top-[20px] absolute origin-top-left" src="@/img/Purchased-AdobeStock_320523164-PhotoRoom_student.png" />
                            <img class="left-[170px] top-[350px] absolute origin-top-left" src="@/img/Group 1000004405.png" />
                        </div>
                    </div>
                </div>
                <div class="w-[1440px] py-[60px] bg-blue-100 flex-col justify-start items-center gap-[34px] flex width=100%">
                    <div class="text-center text-black text-[40px] font-bold leading-[48px]">MAENI by BUCLID</div>
                    <div class="w-[535px] h-[168px] relative">
                        <img class="w-[111px] h-[114px] left-0 top-[49px] absolute" src="@/img/ramp.png" />
                        <img class="w-[197px] h-[114px] left-[159px] top-[49px] absolute" src="@/img/hipaa.png" />
                        <img class="w-[122px] h-[123px] left-[413px] top-[45px] absolute" src="@/img/ferpa.png" />
                        <img class="w-10 h-10 left-[36px] top-0 absolute" src='@/img/check_circle.svg'/>
                        <img class="w-10 h-10 left-[238px] top-0 absolute" src='@/img/check_circle.svg'/>
                        <img class="w-10 h-10 left-[454px] top-0 absolute" src='@/img/check_circle.svg'/>
                        <div class="w-6 h-6 left-[246px] top-[8px] absolute"></div>
                        <div class="w-6 h-6 left-[462px] top-[8px] absolute"></div>
                    </div>
                    <div class="w-[753px] text-center text-black text-[32px] font-normal   leading-[37.61px]">MAENI is a feedback resource for health profession students, a time-saving solution for faculty educators, and an integrated digital dashboard for clerkship coordinators.</div>
                </div>
                <CompLearnMore @requestEmail="requestEmail"/>
                <div class="w-[1440px] h-[323px] relative bg-slate-200">
                    <div class="w-[232px] h-[57px] left-[408px] top-[199px] absolute">
                        <div class="w-48 left-[40px] top-0 absolute text-indigo-950 text-base font-normal  ">3911 4th Street, Lubbock, Texas 79415, United States</div>
                        <div class="w-6 h-6 left-0 top-[10px] absolute"></div>
                    </div>
                    <div class="w-[304px] left-[701px] top-[202px] absolute">
                        <div class="w-[264px] left-[40px] top-[1px] absolute text-indigo-950 text-base font-normal  ">info@buclid.com</div>
                        <div class="w-6 h-6 left-0 top-0 absolute flex-col justify-start items-start inline-flex"></div>
                    </div>
                    <div class="left-[929px] top-[202px] absolute justify-start items-start gap-4 inline-flex">
                        <div class="w-10 h-10 relative"></div>
                        <div class="w-10 h-10 relative"></div>
                        <div class="w-10 h-10 relative"></div>
                    </div>
                    <div class="w-[645px] h-[0px] left-[422px] top-[170px] absolute border border-slate-300"></div>
                    <div class="w-[120px] left-[685px] top-[83px] absolute opacity-50 text-indigo-950 text-[33.10px] font-extrabold font-['Montserrat']">MAENI</div>
                </div>
            </div>
        </div>
    </div>
    </div>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
</template>

<style>
.scale-container {
  transform-origin: top left;
  width: 1440px; /* use natural width */
}

@media (max-width: 1440px) {
  .scale-container {
    transform: scale(calc(100vw / 1440));
  }
}
</style>
