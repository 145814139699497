import { createWebHistory, createRouter } from "vue-router";
// import Home from "@/views/Home.vue";
 import PageLanding from "@/views/PageLanding.vue";
 import PageAbout from "@/views/PageAbout.vue";

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: PageLanding,
  },
  {
    path: "/about",
    name: "AboutUs",
    component: PageAbout,
  }
  ];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
