<template>
  <div class="fixed z-50 flex items-center justify-center" style="top:60%; left:50%; transform: translate(-50%, -50%);">
    <div class="spinner"></div>
  </div>
</template>

<style>
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
