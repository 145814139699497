import { Base64 } from '@/js/utils.js'

export function _requestEmail(vm, emailContents) {

    var encode = Base64.encode(JSON.stringify(emailContents));
    const updateNotifications = async (encode) => {
        try {
            const response = await fetch('https://maeni.buclid.com/api/demail', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'text/plain',
                },
                body: encode
            })

            if (!response.ok) {
                throw new Error('Failed to update notifications.')
            }

            const result = await response.json()

            return result
        }
        catch (error) {
            console.error(error)
        }
    }
    updateNotifications(encode);
}
