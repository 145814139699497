<script>

export default {
    data() {
        return {
        };
    },
    components: {
    },
    methods: {
    },
};
</script>
<template>
        <div class="w-full px-20 py-6 bg-white shadow justify-between items-center inline-flex" style="z-index:950;">
            <div class="justify-start items-center gap-12 flex">
                <div class="w-[120px] h-10 justify-center items-center flex">
                    <div class="w-[120px] text-indigo-950 text-[33.10px] font-extrabold font-['Montserrat']">BUCLID</div>
                </div>
                <div class="justify-start items-center  flex">
                    <div class="w-[70] px-2 py-3 justify-start items-center gap-2 flex">
                        <RouterLink to="/" class="text-indigo-950  font-bold font-['SF Pro Text'] leading-normal " style="font-size: 1.5rem;">Home</RouterLink>
                    </div>
                    <div class="w-[180] px-2 py-3 justify-start items-center gap-2 flex">
                        <RouterLink to="/about" class="text-indigo-950  font-normal font-['SF Pro Text'] leading-normal" style="font-size: 1.5rem;">About Us</RouterLink>
                    </div>
                    <div class="w-[180] px-2 py-3 justify-start items-center gap-2 flex">
                        <a href="https://maeni.buclid.com" target="_blank" class="text-indigo-950  font-normal font-['SF Pro Text'] leading-normal" style="font-size: 1.5rem;">MAENI Login</a>
                    </div>
                    <!-- <div class="w-[127px] px-2 py-3 justify-start items-center gap-2 flex">
                         <RouterLink to="/about" class="text-indigo-950 text-base font-normal font-['SF Pro Text'] leading-normal">Resources</RouterLink>
                         <div class="w-6 h-6 relative"></div>
                         </div> -->
                </div>
            </div>
            <div class="justify-start items-center gap-4 flex">
                <button @click="$emit('openEmailRequestModal')" class="px-[22px] py-[11px] bg-blue-600 rounded-md justify-start items-center gap-[11px] flex">
                    <div  class="text-center text-white  font-semibold font-['SF Pro Text'] leading-snug" style="font-size: 1.5rem;">Book a demo</div>
                </button>
            </div>
        </div>
</template>


<style>
/* ---------------------------------- */
.fade-enter-active,
.fade-leave-active {
  transition: opacity .4s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.pop-enter-active,
.pop-leave-active {
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
  transform: scale(0.3) translateY(-50%);
}
</style>
