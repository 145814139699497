<template>
    <div v-show="emailSentShow" :class="$style.content">
        <div v-if="spin">
            <div :class="$style.title">Sending your request...</div>
            <div :class="$style.body"></div>
            <div :class="$style.body"></div>
            <div :class="$style.body"></div>
            <div :class="$style.body"></div>
            <CompSpinner/>
        </div>
        <div v-else>
            <div :class="$style.autoAddedFrame">
                <img :class="$style.autoAddedFrameChild" alt="" src="@/img/Group 1000004419.png" />
            </div>
            <div :class="$style.title">We’ve received it</div>
            <div :class="$style.body">Looking forward to speak to you soon!</div>
            <div :class="$style.spacer">
                <div :class="$style.spacerChild" />
            </div>
            <div :class="$style.buttons">
                <button @click="$emit('buttonClicked')" :class="$style.button">
                    <div :class="$style.label">Thanks!</div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import CompSpinner from '@/components/CompSpinner.vue'
 export default {
   data() {
     return {
     };
   },
   components: { CompSpinner },
   methods: {},
   props: {
     emailSentShow: {
       type: Boolean,
       required: true,
       default: false
     },
     spin: {
       type: Boolean,
       required: true,
       default: false
     }
   },
 };
</script>

<style module>
 .autoAddedFrameChild {
     width: 355.5px;
     position: relative;
     height: 250px;
     object-fit: cover;
 }
 .autoAddedFrame {
     align-self: stretch;
     overflow: hidden;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: flex-start;
 }
 .title {
     align-self: stretch;
     position: relative;
     line-height: 24px;
     font-weight: 600;
     color: #101840;
 }
 .body {
     width: 528px;
     position: relative;
     font-size: 14px;
     line-height: 20px;
     display: inline-block;
 }
 .spacerChild {
     position: absolute;
     height: 100%;
     width: 100%;
     top: 0%;
     right: 0%;
     bottom: 0%;
     left: 0%;
 }
 .spacer {
     width: 24px;
     position: relative;
     height: 8px;
 }
 .label {
     position: relative;
     line-height: 16px;
     font-weight: 500;
 }
 .button {
     border-radius: 4px;
     background-color: #fff;
     border: 1px solid #d8dae5;
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: flex-start;
     padding: 8px 16px;
 }
 .buttons {
     display: flex;
     flex-direction: row;
     align-items: flex-start;
     justify-content: center;
     text-align: right;
     font-size: 12px;
 }
 .content {

  z-index: 999;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
     box-shadow: 0px 12px 40px rgba(16, 24, 64, 0.24);
     border-radius: 8px;
     background-color: #fff;
     width: 592px;
     display: flex;
     flex-direction: column;
     align-items: flex-end;
     justify-content: flex-start;
     padding: 32px;
     box-sizing: border-box;
     gap: 8px;
     text-align: center;
     font-size: 18px;
     color: #696f8c;
     font-family: 'SF Pro Text';
 }
</style>
