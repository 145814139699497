
<script>

export default {
    data() {
        return {
            email:'',
            name:'',
            role:'',
            message:''
        };
    },
    components: {
    },
    methods: {
    openModal() {
      this.modalOpen = true;
    },
     requestEmail() {
       this.$emit('requestEmail', {
         email:this.email,
         name: this.name,
         role: this.role,
         message: this.message
       });
     }
    },
    props: {
    },
};
</script>

<template>
        <div class="w-[1440px] h-[733px] relative">
            <div class="w-[1440px] h-[933px] left-0 top-0 absolute bg-slate-100"></div>
            <div class="left-[651px] top-[120px] absolute text-right text-black text-[32px] font-normal   leading-[37.61px]">Let’s talk</div>
            <div class="left-[492px] top-[50px] absolute text-right text-black text-5xl font-bold   leading-[57.60px]">Want to learn more?</div>
            <!-- <div class="w-[334px] h-[167px] left-[169px] top-[196px] absolute bg-white shadow border border-slate-100"></div> -->
            <!-- <div class="left-[303px] top-[231px] absolute text-center text-indigo-950 text-xl font-semibold font-['SF Pro Text'] leading-normal">Dr. Hassan</div> -->
            <!-- <div class="w-[334px] h-[167px] left-[534px] top-[196px] absolute bg-white shadow border border-slate-100"></div> -->
            <!-- <div class="w-[334px] h-[167px] left-[899px] top-[196px] absolute bg-white shadow border border-slate-100"></div> -->
            <!-- <img class="w-[103px] h-[103px] left-[188px] top-[231px] absolute rounded-full" src="@/img/elip1.svg" /> -->
            <!-- <img class="w-[103px] h-[103px] left-[555px] top-[231px] absolute rounded-full" src="@/img/elip2.svg" /> -->
            <!-- <img class="w-[103px] h-[103px] left-[924px] top-[231px] absolute rounded-full" src="@/img/elip3.svg" /> -->
            <!-- <div class="left-[679px] top-[231px] absolute text-center text-indigo-950 text-xl font-semibold  leading-normal">Dr. Sarah</div> -->
            <!-- <div class="left-[1056px] top-[231px] absolute text-center text-black text-xl font-semibold font-['SF Pro Text'] leading-normal">Dr. Zeb</div> -->
            <!-- <div class="left-[303px] top-[267px] absolute text-slate-600 text-sm font-normal font-['SF Pro Text'] leading-tight">Qualifications,<br/>Texas, USA</div> -->
            <!-- <div class="left-[679px] top-[267px] absolute text-slate-600 text-sm font-normal font-['SF Pro Text'] leading-tight">Qualifications,<br/>Texas, USA</div> -->
            <!-- <div class="left-[1056px] top-[267px] absolute text-slate-600 text-sm font-normal font-['SF Pro Text'] leading-tight">Qualifications,<br/>Texas, USA</div> -->
            <div class="left-[244px] top-[218px] absolute text-black text-2xl font-semibold font-['SF Pro Text'] leading-loose">Please give us some context</div>
            <div class="w-[396px] h-16 left-[244px] top-[288px] absolute rounded flex-col justify-start items-start gap-2 inline-flex">
                <div class="  h-6 flex-col justify-start items-start gap-1 flex">
                    <div class="  text-slate-600 text-sm font-medium font-['SF Pro Text'] leading-normal">Your name</div>
                </div>
                <div class="w-[396px] h-8 px-3 py-2 bg-white rounded border border-gray-300 justify-center items-center inline-flex">
                    <input v-model="name" v-type="text" class="w-[372px] text-black-400  font-normal font-['SF Pro Text'] leading-none" placeholder="First and last name"/>
                </div>
            </div>
            <div class="w-[396px] h-16 left-[244px] top-[374px] absolute rounded flex-col justify-start items-start gap-2 inline-flex">
                <div class="  h-6 flex-col justify-start items-start gap-1 flex">
                    <div class="  text-slate-600 text-sm font-medium font-['SF Pro Text'] leading-normal">Your role</div>
                </div>
                <div class="w-[396px] h-8 px-3 py-2 bg-white rounded border border-gray-300 justify-center items-center inline-flex">
                    <input v-type="text" v-model="role" class="w-[372px] text-black-400  font-normal font-['SF Pro Text'] leading-none" placeholder="Coordinator, Educator, Administrator, etc.."/>
                </div>
            </div>
            <!-- <div class="w-[396px] h-16 left-[677px] top-[488px] absolute rounded flex-col justify-start items-start gap-2 inline-flex">
                 <div class="self-stretch h-6 flex-col justify-start items-start gap-1 flex">
                 <div class="self-stretch text-slate-600 text-sm font-medium font-['SF Pro Text'] leading-normal">Phone number</div>
                 </div>
                 <div class="w-[396px] h-8 px-3 py-2 bg-white rounded border border-gray-300 justify-center items-center inline-flex">
                 <div class="w-[372px]"></div>
                 </div>
                 </div> -->
            <div class="w-[396px] h-16 left-[244px] top-[460px] absolute rounded flex-col justify-start items-start gap-2 inline-flex">
                <div class="  h-6 flex-col justify-start items-start gap-1 flex">
                    <div class="  text-slate-600 text-sm font-medium font-['SF Pro Text'] leading-normal">Email</div>
                </div>
                <div class="w-[396px] h-8 px-3 py-2 bg-white rounded border border-gray-300 justify-center items-center inline-flex">
                    <input v-type="text" v-model="email" class="w-[372px] text-black-400  font-normal font-['SF Pro Text'] leading-none" placeholder="Your Email"/>
                </div>
            </div>
            <div class="w-[396px] h-24 left-[677px] top-[288px] absolute rounded flex-col justify-start items-start gap-2 inline-flex">
                <div class="h-24 rounded h-[237px] flex-col justify-start items-start gap-2 flex">
                    <div class="  h-6 flex-col justify-start items-start gap-1 flex">
                        <div class="  text-slate-600 text-sm font-medium font-['SF Pro Text'] leading-normal">Give us some context</div>
                    </div>
                    <textarea class="w-[396px] h-[300px] relative bg-white rounded border border-gray-300" v-model="message" placeholder="Which institution do you represent? What are you keen to learn?"></textarea>
                </div>
            </div>
            <button @click="requestEmail" class="left-[430px] h-11 px-[22px] py-[11px] top-[561px] absolute bg-blue-600 rounded-md justify-start items-center gap-[11px] inline-flex" style="padding-right: 200px; padding-left: 200px; padding-top:50px; padding-bottom:50px;">
                <div class="text-center text-white text-base font-semibold font-['SF Pro Text'] leading-snug" style="font-size: 2rem;">Submit</div>
            </button>
        </div>
</template>
